import { toast } from 'react-toastify';

const success = (message: string) => {
    toast.success(message);
}

const warning = (message: string) => {
    toast.warning(message);
}

const error = (message: string) => {
    toast.error(message);
}

const info = (message: string) => {
    toast.info(message);
}

export const notifications = {
    success,
    warning,
    error,
    info
}