import { useEffect, lazy, useRef, useState } from 'react';
import { HashRouter, Routes, Route, useNavigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import { MsalProvider } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';
import { ToastContainer } from 'react-toastify';

import { useAppDispatch, useAppSelector } from './store/hooks';
import { store } from './store';

import Layout from './components/layout/Layout';

import './App.scss';

import { appSettingsActions, selectAppSettings } from './features/app-settings/app-settings-slice';
import createMsalInstance from './features/auth/msal-instance';

import { CustomNavigationClient } from './utils/NavigationClient';
import AuthenticatedRoute from './components/auth/AuthenticatedRoute';
import { userProfileActions } from './features/auth/user-slice';
import '@progress/kendo-theme-fluent/dist/all.css';
import UserInfo from './pages/user-info/UserInfo';
import GlobalLoader from './components/common/global-loader/global-loader';

const Home = lazy(() => import('./pages/home/Home'));
const NotFound = lazy(() => import('./pages/404/NotFound'));
const Sources = lazy(() => import('./pages/sources/Sources'));

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <HashRouter>
        <App />
        <GlobalLoader></GlobalLoader>
      </HashRouter>
    </Provider>
  );
};

const App = () => {
  const msalRef = useRef<IPublicClientApplication | null>(null);
  const [msal, setMsal] = useState<IPublicClientApplication | null>(null);
  const dispatch = useAppDispatch();
  const history = useNavigate();
  const settings = useAppSelector(selectAppSettings).data;

  useEffect(() => {
    dispatch(appSettingsActions.fetchSettings());
  }, [dispatch]);

  useEffect(() => {
    if (settings && !msalRef.current) {
      const msalInstance = createMsalInstance(settings, (account) => {
        setTimeout(() => {
          dispatch(
            userProfileActions.setProfile({
              username: account?.username || '',
              name: account?.name || '',
            }),
          );
        }, 1);
      });

      msalInstance.handleRedirectPromise().then((res) => {
        if (res !== null) {
          dispatch(
            userProfileActions.setProfile({
              username: res.account?.username || '',
              name: res.account?.name || '',
            }),
          );
        }
      });

      const navigationClient = new CustomNavigationClient(history);
      msalInstance.setNavigationClient(navigationClient);
      msalRef.current = msalInstance;
      setMsal(msalInstance);
    }
  }, [dispatch, history, settings]);

  if (!settings || !msal) {
    return <div></div>;
  }

  return (
    <MsalProvider instance={msal}>
      <Layout>
        <Routes>
          <Route element={<AuthenticatedRoute requireAuth={false} redirectPath='/accounts' />}>
            <Route path='/' element={<Home />} />
          </Route>

          <Route element={<AuthenticatedRoute requireAuth={true} redirectPath='/' />}>
            <Route path='/accounts' element={<Sources />} />
            {/*<Route path='/user-info' element={<UserInfo />} />*/}
          </Route>

          <Route path='*' element={<NotFound />} />
        </Routes>
      </Layout>

      <ToastContainer autoClose={2000} position='bottom-right' />
    </MsalProvider>
  );
};

export default AppWrapper;
