import React, { FunctionComponent, Suspense } from 'react';

import Header from './header/Header';
import styles from './layout.module.scss';

interface Props {
  children: React.ReactNode;
}

const Layout: FunctionComponent<Props> = ({ children }) => (
  <div className={styles.app}>
    <Header />
    <Suspense>
      <main className={styles.main}>{children}</main>
    </Suspense>
  </div>
);

export default Layout;
