import { PopupRequest } from '@azure/msal-browser';
import { store } from '../../store';

export const getLoginRequest = function (): PopupRequest {
  let request: PopupRequest = {
    scopes: store.getState().appSettings.data?.aad.scopes ?? [],
    prompt: 'select_account',
  };
  return request;
};

export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft-ppe.com/v1.0/me',
};
