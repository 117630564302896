import {
  AccountInfo,
  AuthenticationResult,
  BrowserCacheLocation,
  EventMessage,
  EventType,
  InteractionRequiredAuthError,
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';

import { Configuration } from '@azure/msal-browser';

import { IAppSettings } from '../../model';
import { getLoginRequest } from './msal-config';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

const msalConfig: Configuration = {
  auth: {
    clientId: '',
    authority: '',
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: isIE,
  },
};

let instance: IPublicClientApplication | null = null;

export default function createMsalInstance(
  appSettings: IAppSettings,
  onAuthenticated: (res: AccountInfo | null) => void,
): IPublicClientApplication {
  const config = {
    ...msalConfig,
    ...{
      auth: {
        clientId: appSettings.aad.clientId,
        authority: appSettings.aad.authority,
      },
    },
  };

  const msalInstance = new PublicClientApplication(config);

  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
    onAuthenticated(accounts[0]);
  }

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
      onAuthenticated(account);
    }
  });
  instance = msalInstance;
  return msalInstance;
}

export function getInstance(): IPublicClientApplication | null {
  return instance;
}

export async function aqcuireToken(scopes: string[]): Promise<string> {
  const msalInstance = getInstance();
  if (!msalInstance) {
    throw new Error('MSAL instance is not initialized.');
  }

  const account = msalInstance?.getAllAccounts()[0];

  let accessTokenResponse: AuthenticationResult;
  try {
    accessTokenResponse = await msalInstance.acquireTokenSilent({
      scopes: scopes,
      account: account,
    });

    return accessTokenResponse.accessToken;
  } catch (err) {
    if (err instanceof InteractionRequiredAuthError) {
      return await aqcuireTokenPopup(scopes);
    }

    throw err;
  }
}

export async function aqcuireTokenPopup(scopes: string[]): Promise<string> {
  try {
    const msalInstance = getInstance();
    if (!msalInstance) {
      throw new Error('MSAL instance is not initialized.');
    }

    const accessTokenResponse = await msalInstance.acquireTokenPopup({
      scopes: scopes,
      prompt: 'select_account',
    });

    return accessTokenResponse.accessToken;
  } catch (err) {
    await aqcuireTokenRedirect(scopes);
    return '';
  }
}

export async function aqcuireTokenRedirect(scopes: string[]): Promise<void> {
  const msalInstance = getInstance();
  if (!msalInstance) {
    throw new Error('MSAL instance is not initialized.');
  }

  await msalInstance.acquireTokenRedirect({
    scopes: scopes,
    redirectUri: '/',
    prompt: 'select_account',
  });
}

export async function logout(): Promise<void> {
  const msalInstance = getInstance();
  try {
    await msalInstance?.logoutPopup();
  } catch (err) {
    await msalInstance?.logoutRedirect({ postLogoutRedirectUri: '/' });
  }
}

export async function login(): Promise<void> {
  const msalInstance = getInstance();
  try {
    await msalInstance?.loginPopup(getLoginRequest());
  } catch (err) {
    await msalInstance?.loginRedirect(getLoginRequest());
  }
}
